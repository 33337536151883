<template>
    <div>
        <div class="page-header hidden-sm-and-down">
            <div style="float:right">
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="医生排期"/>
        </div>
        <div class="calendar">
            <el-calendar v-model="currentDate"
                         @prev-month="getData"
                         @next-month="getData">
                <template slot="dateCell" slot-scope="{date, data}">
                    <div >
                        <div @click="viewDetail(data.day)">{{ data.day.split('-').slice(1).join('-') }}</div>
                        <div style="width:100%;" v-for="(item,index) in scheduleData" :key="index" @click="viewDetail(data.day,item)">
                            <el-popover
                                    placement="top-start"
                                    width="200"
                                    trigger="hover"
                                    >
                                <div> {{item.nickname_list.join("、")}}</div>
                                <div v-if="(item.duty_date).indexOf(data.day)!=-1" style="padding: 5px 0" slot="reference">
                                    <el-tag type="" size="mini">
                                        {{item.nickname_list.length}} 人： <span>{{item.duty_time.join("-")}}</span>
                                    </el-tag>
                                </div>
                            </el-popover>

                        </div>
                    </div>
                </template>
            </el-calendar>
        </div>
        <el-dialog :title="`排期: ${form.duty_date}`" :visible="dialogVisible" width="30%"
                   :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form" size="small">
                <el-form-item label="值班医生" prop="user_id_list">
                    <el-select v-model="form.user_id_list" placeholder="请选择" style="width: 100%" multiple collapse-tags>
                        <el-option
                                v-for="item in doctorList"
                                :key="item.id"
                                :label="item.nickname"
                                :value="item.id.toString()">
                            <span style="float: left">{{ item.nickname }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.companyName }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="值班时间" prop="duty_time">
                    <el-time-picker
                            style="width: 100%"
                            is-range
                            v-model="form.duty_time"
                            value-format="HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围">
                    </el-time-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" @click="handleDelete(form)" v-if="form.id" style="float:left">删 除</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                form: {
                    user_id_list:[],
                    duty_time: null
                },
                currentDate:"",
                dialogVisible: false,
                scheduleData: [],
                doctorList: [],
                date: "",
                rules: {
                    duty_time: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    user_id_list: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                }
            }
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            currentDate(newValue, oldValue) {
                this.date = this.extractYearAndMonth(newValue)
                this.getData()
            }
        },
        methods: {
            handleDelete(row) {
                this.$confirm(`确认要删除吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/company/scheduling/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') {
                                this.getData()
                                this.dialogVisible = false
                            }
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }).catch(() => {
                });
            },
            extractYearAndMonth(day) {
                const date = new Date(day);
                let year = date.getFullYear(); // 获取年份
                // 获取月份，月份索引从0开始，所以加1，然后使用padStart确保两位数格式
                let month = (date.getMonth() + 1).toString().padStart(2, '0');
                return `${year}-${month}`;
            },
            viewDetail(day,items=null) {
                console.log(items)
                if(items){
                    this.form.duty_date = day
                    this.form.duty_time = items.duty_time
                    this.form.user_id_list = items.user_id_list
                    this.form.id = items.id

                }else {
                    this.form.duty_date = day
                    this.form.duty_time = null
                    this.form.user_id_list = []
                    this.form.id = ""
                }
                this.dialogVisible = true;
            },
            async getData() {
                const resp = await this.$http.get(`/company/scheduling/calendar?date=${this.date}`)
                this.scheduleData = resp.data.data
            },
            async getUserData() {
                try {
                    this.$http.post(`/auth/list`, {page: 1, pageSize: 500, user_type: "5"})
                        .then(resp => {
                            this.doctorList = resp.data.data
                        }).catch(err => {
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.$message.error(error.message)
                }
            },
            async submit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    var dataForm = JSON.parse(JSON.stringify(this.form))
                    dataForm.duty_month = this.extractYearAndMonth(dataForm.duty_date)
                    let url = `/company/scheduling`
                    const resp = await this.$http.post(url, dataForm)
                    this.$message[resp.data.status](resp.data.msg)
                    this.getData()
                    this.dialogVisible = false
                } catch (e) {
                    this.$message.error(e.message)
                }
            }
        },
        mounted() {
            this.getUserData()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .mydate-picker {
        width: 140px;
    }

    ::v-deep .mydate-picker input {
        cursor: pointer;
    }

    a {
        text-decoration: none;
        color: #333;
    }

    .calendar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;

        .header {
            font-weight: bold;
            font-size: 110%;
            line-height: 50px;
            min-height: 50px !important;
        }

        .box {
            width: 14.28%;
            min-height: 100px;
            /*margin-bottom: 2px;*/
            border-bottom: 1px solid #ccc;
            cursor: pointer;

            .day {
                padding-top: 10px;
                font-size: 150%;
            }

            .light {
                color: #aaa;
            }
        }

        .box:hover {
            background-color: #ecf5ff;
        }
    }
</style>
